import { hrefs } from "utils"

import { ReactComponent as Instagram } from "assets/icons/instagram.svg"
import { ReactComponent as Medium } from "assets/icons/medium.svg"
import { ReactComponent as Discord } from "assets/icons/discord.svg"
import { ReactComponent as Twitter } from "assets/icons/twitter.svg"
import "./Footer.scss"

export const Footer = (): JSX.Element => {
  const links = [
    {
      icon: <Twitter />,
      link: hrefs.twitter,
    },
  ]

  return (
    <div className="footer">
      <div className="footer-wrap">
        {links.map((item, index) => (
          <a className="footer-link" rel="noreferrer" key={index} href={item.link} target="_blank">
            {item.icon}
          </a>
        ))}
      </div>
    </div>
  )
}
