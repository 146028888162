import { Discord, Help, Layout, Members, Mice, Roadmap, Team } from "components"

type Props = {
  redirect: boolean
}

export const Home = ({ redirect }: Props): JSX.Element => {
  return (
    <Layout background={"home"} redirect={redirect}>
      <Help />
      <Mice />
      <Members />
      <Roadmap />
      <Team />
    </Layout>
  )
}
