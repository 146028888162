import { useMedia } from "use-media"

import imgBgSrc from "assets/images/help.webp"
import imgBgMobileSrc from "assets/images/help-mobile.webp"
import "./Help.scss"

export const Help = (): JSX.Element => {
  const isS = useMedia({ maxWidth: "600px" })

  return (
    <div className="help">
      <p className="help-desc">
        Mimi accidentally discovered <br /> <span className="gold">Jet Foundation</span> while escaping from{" "}
        {!isS && <br />}
        humans.
        <br />
        They now need a lot of {isS && <br />} cheesy to {!isS && <br />} enter the portal.
      </p>
      <img src={imgBgSrc} alt="clouds" className="help-bg" />
      <img src={imgBgMobileSrc} alt="clouds" className="help-bg-mobile" />
    </div>
  )
}
