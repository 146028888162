import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useState } from "react"

import { NotFound, Home, ComingSoon, FAQ, Token, MouseInfo, Mimi, Mint, Store } from "pages"
import { routes } from "utils"

export const Router = (): JSX.Element => {
  const [redirect, setRedirect] = useState(false)

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<ComingSoon onRedirect={() => setRedirect(true)} />} />
        <Route path={routes.home} element={<Home redirect={redirect} />} />
        <Route path={routes.faq} element={<FAQ />} />
        <Route path={routes.token} element={<Token />} />
        <Route path={routes.info} element={<MouseInfo />} />
        <Route path={routes.mimi} element={<Mimi />} />
        <Route path={routes.mint} element={<Mint />} />
        <Route path={routes.store} element={<Store />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
