import img1Src from "assets/images/members/1.webp"
import img2Src from "assets/images/members/2.webp"
import img3Src from "assets/images/members/3.webp"
import img4Src from "assets/images/members/4.webp"
import img5Src from "assets/images/members/5.webp"
import img6Src from "assets/images/members/6.webp"
import "./Members.scss"

export const Members = (): JSX.Element => {
  const members = [
    {
      img: img1Src,
      title: "METAVERSE GALLERY",
      desc: (
        <p className="members-list-item-desc">
          <b>Exclusive</b> access and opportunities to display and trade your Mimi.
        </p>
      ),
    },
    {
      img: img2Src,
      title: "LET'S PARTY",
      desc: (
        <p className="members-list-item-desc">
          Join our lands in <b>The Sandbox</b> with your Mimi VX.
        </p>
      ),
    },
    {
      img: img3Src,
      title: "FREE MINT-PASS",
      desc: (
        <p className="members-list-item-desc">
          Receive a <b>free mint-pass</b> for future minting possibilities.
        </p>
      ),
    },
    {
      img: img4Src,
      title: "COMMERCIAL RIGHTS",
      desc: (
        <p className="members-list-item-desc">
          Get <b>full ownership</b> of your Mimi NFT, including both creative and commercial rights.
        </p>
      ),
    },
    {
      img: img5Src,
      title: "HOLDER'S PROFIT",
      desc: (
        <p className="members-list-item-desc">
          Holders will get <b>20%</b> ETH from all fees collected on OpenSea.
        </p>
      ),
    },
    {
      img: img6Src,
      title: "DAO",
      desc: (
        <p className="members-list-item-desc">
          Holders can <b>vote on the direction</b> of community development, such as land purchases, regular parties and
          roadmaps, etc.
        </p>
      ),
    },
  ]

  return (
    <div className="members">
      <div className="members-title">EXCLUSIVE MEMBERS-ONLY BENEFITS</div>
      <div className="members-list">
        {members.map((item, index) => (
          <div key={index} className="members-list-item">
            <div className="members-list-item-title">{item.title}</div>
            <img alt="member" className="members-list-item-img" src={item.img} />
            <div className="members-list-item-desc">{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
