import { useMedia } from "use-media"

import { Layout } from "components"
import { mixins, useWindowHeight } from "utils"

import imgMimiBgSrc from "assets/gifs/mimi.gif"
import "./Mimi.scss"

export const Mimi = (): JSX.Element => {
  const isS = useMedia({ maxWidth: mixins.s })
  const height = useWindowHeight()

  return (
    <Layout background={"mimi"}>
      <div className="mimi" style={{ minHeight: isS ? "auto" : height - 160 }}>
        {isS && <img src={imgMimiBgSrc} className="mimi-img" alt="bg" />}
        <div className="mimi-block">
          <p className="mimi-block-desc">
            Jet Foundation is a magical Chessy-filled universe. The adventurous spirit of the charming mouse, who loves
            nothing beyond cheese, inspired CheedarVerse. Our Stuart Little discovered this magical paradise with an
            unlimited supply of Cheesy while getting away from humans one day. He became the leader of it and encouraged
            everyone to join him. We intend to provide an enjoyable environment and communication on behalf of all NFT
            collectors in the crypto-verse by collecting these adventurous and daunting Mimi on the Ethereum blockchain.
            We wish to bring all Mimi fans together by forming a powerful community through our NFTs.
            <br />
            <br />
            Each of our Mimi begins as Genesis Mimi. However, their fondness for cheese causes them to morph into a
            multidimensional Mimi VX. Each Genesis Mimi can gather up to 5 Cheesy tokens per day, and when they get 300
            Cheesy tokens they turn into a multidimensional Mimi VX. Each Mimi grants you absolute ownership of your NFT
            and backs you as an entrant to the wholly-owned mouse burrow. Each Mimi NFT is a unique combination of
            beautifully handcrafted traits and comes with full commercial IP rights for the owner.
            <br />
            <br />
            Offering a wholesome and supportive community where people can learn and grow together.{" "}
          </p>
        </div>
      </div>
    </Layout>
  )
}
