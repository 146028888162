import { useState } from "react"
import { useNavigate } from "react-router"

import { Footer, Loading } from "components"

import imgLogoSrc from "assets/images/logo.png"
import imgBgSrc from "assets/images/phone-bg.webp"
import "./ComingSoon.scss"
import { routes } from "utils"

type Props = {
  onRedirect: () => void
}

export const ComingSoon = ({ onRedirect }: Props): JSX.Element => {
  const [firstLoad, setFirstLoad] = useState(true)
  const navigate = useNavigate()

  const onClick = () => {
    onRedirect()
    navigate(routes.home)
  }

  return (
    <div className="coming-soon-wrapper">
      {firstLoad && <Loading />}
      <div className="coming-soon-wrapper-bg">
        <iframe
          src="https://streamable.com/e/fhcuj1?autoplay=1&nocontrols=1&muted=1"
          frameBorder="0"
          width="100%"
          height="100%"
          allowFullScreen
          onLoad={() => setFirstLoad(false)}
          allow="autoplay"
        />
        <img src={imgBgSrc} alt="bg" className="phone-bg" />
      </div>
      <img src={imgLogoSrc} alt="logo" className="coming-soon-wrapper-logo" />
      <div className="coming-soon-wrapper-text">
        <h1 className="coming-soon-wrapper-text-header">Jet Foundation</h1>
        <p className="coming-soon-wrapper-text-desc">
          Once there lived a mouse named Stuart Little. He loves eating cheese. He was on the way to stealing cheese,
          but it was never an easy task. One day Stuart walking around, and discovered a portal to another world that
          was full of Cheddar. It was named Jet Foundation. But to unlock the portal he needed a lot of Chessy . So Stuart
          started a mission to collect Chessy. Finally, Stuart entered the magical world of Jet Foundation after
          collecting enough Chessy. It was full of unlimited Cheddar. The land, mountains, houses everything was made of
          Cheese. Stuart became the leader and invited you to Jet Foundation.
        </p>
        <button className="coming-soon-wrapper-text-btn" type="button" onClick={onClick}>
          Get started
        </button>
      </div>
      <Footer />
    </div>
  )
}
