import img1Src from "assets/gifs/team/1.gif"
import img2Src from "assets/gifs/team/2.gif"
import img3Src from "assets/images/team/3.webp"
import img4Src from "assets/gifs/team/4.gif"
import img5Src from "assets/gifs/team/5.gif"
import img6Src from "assets/gifs/team/6.gif"
import img7Src from "assets/gifs/team/7.gif"
import img8Src from "assets/images/team/8.webp"
import img9Src from "assets/images/team/9.webp"
import img10Src from "assets/images/team/10.webp"
import img11Src from "assets/gifs/team/11.gif"
import img12Src from "assets/gifs/team/12.gif"

export const teamData = [
  {
    img: img1Src,
    title: "6uo",
    position: "Executive Director",
  },
  {
    img: img2Src,
    title: "JM9",
    position: "Project Manager",
  },
  {
    img: img3Src,
    title: "Ghost Mouse",
    position: "2D Designer",
  },
  {
    img: img4Src,
    title: "YK",
    position: "2D Designer",
  },
  {
    img: img5Src,
    title: "Mialyk",
    position: "3D Art",
  },
  {
    img: img6Src,
    title: "AaronHC",
    position: "3D Building Modeler",
  },
  {
    img: img7Src,
    title: "MuscaGOR",
    position: "Community Manager",
  },
  {
    img: img8Src,
    title: "Sydney Mouse",
    position: "Social Media Manager",
  },
  {
    img: img9Src,
    title: "EV",
    position: "Marketing",
  },
  {
    img: img10Src,
    title: "JA",
    position: "Frontend Dev",
  },
  {
    img: img11Src,
    title: "K",
    position: "Backend Dev",
  },
  {
    img: img12Src,
    title: "Boom M",
    position: "BlockChain Dev",
  },
]
