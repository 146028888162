import { hrefs } from "utils"

import img1Src from "assets/images/roadmap/1.webp"
import img2Src from "assets/images/roadmap/2.webp"
import img3Src from "assets/images/roadmap/3.webp"
import img4Src from "assets/images/roadmap/4.webp"
import "./Roadmap.scss"

export const Roadmap = (): JSX.Element => {
  const roadmap = [
    {
      img: img1Src,
      title: "PHASE 1",
      desc: (
        <ul>
          <li>
            <span className="roadmap-ok">✅</span>
            Social networks <br />
            <a target="_blank" rel="noreferrer" href={hrefs.discord}>
              Discord
            </a>{" "}
            |{" "}
            <a target="_blank" rel="noreferrer" href={hrefs.twitter}>
              Twitter
            </a>{" "}
            |{" "}
            <a target="_blank" rel="noreferrer" href={hrefs.instagram}>
              Instagram
            </a>{" "}
            |{" "}
            <a target="_blank" rel="noreferrer" href={hrefs.medium}>
              Medium
            </a>
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Coming soon page
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Website Launch
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Whitelist Activity
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Mint website – Countdown
          </li>
        </ul>
      ),
    },
    {
      img: img2Src,
      title: "PHASE 2",
      desc: (
        <ul>
          <li>
            <span className="roadmap-ok">✅</span>1st Whitelist PreSale (250 Mimi NFTs)
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Airdrop of 250 Genesis Mimi NFTs
          </li>
          <li>
            <span className="roadmap-ok">✅</span>2nd Whitelist Sale (250 Mimi NFTs)
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Buy land at The Sandbox for all Mimi VX owners to use
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Public Sale (Mimi NFTs)
          </li>
          <li>
            <span className="roadmap-ok">✅</span>2D Mint Completion (Total 1502 Genesis Mimi NFTs)
          </li>
          <li>
            <span className="roadmap-ok">✅</span>Secondary Market Listing (Opensea, LooksRare)
          </li>
        </ul>
      ),
    },
    {
      img: img3Src,
      title: "PHASE 3",
      desc: (
        <ul>
          <li>
            <span className="roadmap-ok">✅</span>
            Verified Holder Channel in{" "}
            <a target="_blank" rel="noreferrer" href={hrefs.discord}>
              Discord
            </a>
          </li>
          <li>Cheesy Token Launch</li>
          <li>Genesis Mimi NFT owner earn Cheesy</li>
          <li>Exclusive Merchandise Online Shop</li>
          <li>Jet Foundation Indie Game</li>
          <li>NFT World Jet Foundation</li>
          <li>Buy land at NFT World for all Mimi VX owners to use</li>
          <li>Jet Foundation&apos;s experience scene at Oncyber</li>
          <li>DAO setup</li>
          <li>Mimi VX Presale</li>
        </ul>
      ),
    },
    {
      img: img4Src,
      title: "PHASE 4",
      desc: (
        <ul>
          <li>Jet Foundation built in Metaverse (The Sandbox) with Mimi VX NFTs</li>
          <li>Extra Metaverse lands and Buildings created for Holder&apos;s use</li>
          <li>Jet Foundation NFTs shop</li>
          <li>Metaverse Party for all Holders with music by international DJ</li>
        </ul>
      ),
    },
  ]

  return (
    <div className="roadmap">
      <div className="roadmap-title">Roadmap</div>
      <div className="roadmap-content">
        <div className="roadmap-border-left" />
        <div className="roadmap-list">
          {roadmap.map((item, index) => (
            <div key={index} className="roadmap-list-item">
              <div className="roadmap-list-item-border" />
              <img alt="member" className={`roadmap-list-item-img-${index + 1}`} src={item.img} />
              <div className="roadmap-list-item-content">
                <div className="roadmap-list-item-title">{item.title}</div>
                <div className="roadmap-list-item-desc">{item.desc}</div>
              </div>
            </div>
          ))}
          <div key={7} className="roadmap-list-item no-border">
            <div className="roadmap-list-item-border" />
            <div className="roadmap-list-item-other">
              More development roadmap coming soon... <span className="blue">Stay tuned & Join us!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
