import { useNavigate } from "react-router"
import { useMedia } from "use-media"

import { links, mixins, routes, socials } from "utils"

import imgLogoSrc from "assets/images/logo.png"
import "./NewFooter.scss"

export const NewFooter = (): JSX.Element => {
  const isM = useMedia({ maxWidth: mixins.m })
  const navigate = useNavigate()

  return (
    <div className="new-footer">
      <div className="new-footer-left">
        <div className="new-footer-left-mobile">
          <div
            onClick={() => {
              navigate(routes.home)
              location.reload()
            }}
            className="new-footer-logo"
          >
            <img src={imgLogoSrc} alt="logo" className="new-footer-logo-img" />
          </div>
          {isM && (
            <div className="new-footer-left-mobile-socials">
              {socials.map((social, index) => (
                <a className="new-footer-social-item" rel="noreferrer" key={index} href={social.link} target="_blank">
                  {social.icon}
                </a>
              ))}
            </div>
          )}
        </div>
        {isM && (
          <div className="new-footer-links">
            {links.map((link, index) =>
              link.link === "" ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="new-footer-links-item bold"
                  key={index}
                >
                  {link.title}
                </a>
              ) : (
                <div
                  onClick={() => {
                    navigate(link.link)
                    location.reload()
                  }}
                  className="new-footer-links-item bold"
                  key={index}
                >
                  {link.title}
                </div>
              ),
            )}
          </div>
        )}
      </div>
      {!isM && (
        <div className="new-footer-links">
          {links.map((link, index) =>
            link.link === "" ? (
              <a
                target="_blank"
                rel="noreferrer"
                className="new-footer-links-item bold"
                key={index}
              >
                {link.title}
              </a>
            ) : (
              <div
                onClick={() => {
                  navigate(link.link)
                  location.reload()
                }}
                className="new-footer-links-item bold"
                key={index}
              >
                {link.title}
              </div>
            ),
          )}
          {socials.map((social, index) => (
            <a className="new-footer-social-item" rel="noreferrer" key={index} href={social.link} target="_blank">
              {social.icon}
            </a>
          ))}
        </div>
      )}
    </div>
  )
}
