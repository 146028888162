import { ReactNode } from "react"
import { routes } from "./paths"
import { hrefs } from "./sources"

import { ReactComponent as Mail } from "assets/icons/mail.svg"
import { ReactComponent as Twitter } from "assets/icons/twitter.svg"

export const links: {
  title: string
  link: string
}[] = [
  {
    title: "Genesis Mimi",
    link: routes.info,
  },
  {
    title: "Mimi VX",
    link: routes.mimi,
  },
  {
    title: "Buy",
    link: routes.mint,
  },
  {
    title: "F&Q",
    link: routes.faq,
  },
]

export const socials: {
  icon: ReactNode
  link: string
}[] = [
  {
    link: hrefs.mail,
    icon: <Mail />,
  },
  {
    icon: <Twitter />,
    link: hrefs.twitter,
  },
]
