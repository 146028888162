export const routes = {
  index: "/",

  home: "/home",
  faq: "/faq",
  token: "/cheesy",
  info: "/genesismimi",
  mimi: "/mimivx",
  mint: "/mint",
  store: "/store",
}
