import { Layout } from "components"

import { mouseInfoData } from "./mouseInfoData"

import "./MouseInfo.scss"
import React from "react"
import cn from "classnames"

export const MouseInfo = (): JSX.Element => {
  return (
    <Layout background={"faq"}>
      <div className="mouse-info">
        <h1 className="mouse-info-title">Genesis MiMi</h1>
        <div className="mouse-info-blocks">
          {mouseInfoData.map((item, index) => (
            <div key={index} className="mouse-info-block">
              <div className="mouse-info-block-title">{item.title}</div>
              <img className="mouse-info-block-img" src={item.img} alt="mouse" />
              {item.steps.map((step, indexStep) => (
                <div
                  key={indexStep}
                  className={cn(
                    "mouse-info-block-step",
                    "animate__animated animate__fadeInLeft",
                    `animate__delay-${((indexStep + 1) * 0.5).toString().replaceAll(".", "")}s`,
                  )}
                >
                  {step.available && <span>✔</span>}
                  <span
                    className={cn("mouse-info-block-step-label", {
                      disabled: !step.available,
                    })}
                  >
                    {step.label}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
