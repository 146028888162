import { Collapse, Layout } from "components"

import { faqData } from "./faqData"

import "./FAQ.scss"

export const FAQ = (): JSX.Element => {
  return (
    <Layout background={"faq"}>
      <div className="faq">
        <h1 className="faq-title">Frequently asked questions</h1>
        <div className="faq-questions">
          {faqData.map((item, index) => (
            <Collapse key={index} title={item.title} desc={item.desc} />
          ))}
        </div>
      </div>
    </Layout>
  )
}
