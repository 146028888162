import { useEffect, useState } from "react"
import { ethers } from "ethers"

import { claimContractAddress } from "abi"
import abi from "abi/abiClaim/abiClaim.json"

import "./Claim.scss"

export const Claim = (): JSX.Element => {
  const [currentAccount, setCurrentAccount] = useState<any>(null)
  const [balance, setBalance] = useState(0)
  const [claimable, setClaimable] = useState(0)

  const checkWalletIsConnected = async () => {
    // @ts-ignore
    const { ethereum } = window

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!")
      return
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: "eth_accounts" })

    if (accounts.length !== 0) {
      const account = accounts[0]
      console.log("Found an authorized account: ", account)
      setCurrentAccount(account)
    } else {
      console.log("No authorized account found")
    }
  }

  const connectWalletHandler = async () => {
    // @ts-ignore
    const { ethereum } = window

    if (!ethereum) {
      alert("Please install Metamask!")
    }

    try {
      const accounts = await ethereum.request({ method: "eth_requestAccounts" })
      console.log("Found an account! Address: ", accounts[0])
      setCurrentAccount(accounts[0])
    } catch (err) {
      console.log(err)
    }
  }

  const claimHandler = async () => {
    // @ts-ignore
    const { ethereum } = window
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    const nftContract = new ethers.Contract(claimContractAddress, abi, signer)

    try {
      // @ts-ignore
      const { ethereum } = window

      if (ethereum) {
        const nftTxn = await nftContract.claim(currentAccount)

        console.log("Claiming... please wait")
        await nftTxn.wait()

        console.log(`Claimed!`)
      } else {
        console.log("Ethereum object does not exist")
      }
    } catch (err: any) {
      console.log(err)
      if (err.match("funds for gas")) {
        return alert("Not enough gasfee!")
      }
      alert(err)
    }
  }

  const getValues = async () => {
    // @ts-ignore
    const { ethereum } = window
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    const nftContract = new ethers.Contract(claimContractAddress, abi, signer)

    if (currentAccount?.length) {
      const balanceValue = await nftContract.balanceOf(currentAccount)
      const claimableValue = await nftContract.getClaimable(currentAccount)

      if (parseInt(claimableValue, 18) > 0) {
        setClaimable(claimableValue / 1000000000000000000)
      } else {
        setClaimable(0)
      }

      if (parseInt(balanceValue, 18) > 0) {
        setBalance(balanceValue / 1000000000000000000)
      } else {
        setBalance(0)
      }

      console.log(claimableValue)
      console.log(claimable)
    }
  }

  useEffect(() => {
    checkWalletIsConnected()
  }, [])

  useEffect(() => {
    const id = setInterval(getValues, 2000)
    return () => clearInterval(id)
  }, [setBalance])

  getValues()

  return (
    <div className="claim">
      {!currentAccount && (
        <div className="claim-connect">
          <button type="button" onClick={connectWalletHandler} className="claim-connect-btn">
            Connect Wallet
          </button>
        </div>
      )}
      <div className="claim-left">
        <h1 className="claim-title">$CHeesy claim</h1>
        <div className="claim-sub-title">In circulation: {balance} $CHEESY</div>
        <div className="claim-desc">Mimi yields 5 $CHEESY per day.</div>
        <div className="claim-user">
          <div className="claim-user-info">
            <div className="claim-user-info-title">Wallet Profile</div>
            <div className="claim-user-info-value">
              {currentAccount
                ? `${currentAccount.slice(0, 2)}...${currentAccount.slice(
                    currentAccount.length - 4,
                    currentAccount.length,
                  )}`
                : "0x...0000"}
            </div>
          </div>
          <div className="claim-user-info">
            <div className="claim-user-info-title">$CHEESY Balance</div>
            <div className="claim-user-info-value">{claimable}</div>
          </div>
        </div>
      </div>
      <div className="claim-right">
        {/*<input*/}
        {/*  value={input}*/}
        {/*  onChange={e => {setInput(e.target.value)}}*/}
        {/*  type="number"*/}
        {/*  placeholder='0'*/}
        {/*  className="claim-right-input"*/}
        {/*/>*/}
        <div className="claim-right-desc">Claimable $CHESSY</div>
        <button type="button" onClick={claimHandler} className="claim-right-btn" disabled={claimable === 0}>
          Claim
        </button>
      </div>
    </div>
  )
}
