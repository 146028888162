import img1Src from "assets/images/mouse-info/2.webp"
import img2Src from "assets/gifs/mouse-info.gif"

export const mouseInfoData: {
  title: string
  img: string
  steps: {
    label: string
    available: boolean
  }[]
}[] = [
  {
    title: "Regular Genesis MiMi",
    img: img1Src,
    steps: [
      {
        label: "Get 5 $CHEESY every day",
        available: true,
      },
      {
        label: "Free Mimi VX",
        available: true,
      },
      {
        label: "Free rare Mimi VX",
        available: false,
      },
      {
        label: "Free 3D Avatars playable on The Sandbox",
        available: true,
      },
      {
        label: "Play to Earn game",
        available: true,
      },
      {
        label: "Join Jet Foundation Land in The Sandbox",
        available: true,
      },
      {
        label: "Rare Mimi VX Exclusive Land",
        available: false,
      },
      {
        label: "Free exclusive Merchandise",
        available: false,
      },
      {
        label: "Rare Mimi VX channel",
        available: false,
      },
      {
        label: "Receive 20% ETH from all the sales royalty fee",
        available: true,
      },
    ],
  },
  {
    title: "Rare Genesis Mimi",
    img: img2Src,
    steps: [
      {
        label: "Get 5 $CHEESY every day",
        available: true,
      },
      {
        label: "Free Mimi VX",
        available: false,
      },
      {
        label: "Free rare Mimi VX",
        available: true,
      },
      {
        label: "Free 3D Avatars playable on The Sandbox",
        available: true,
      },
      {
        label: "Play to Earn game",
        available: true,
      },
      {
        label: "Join Jet Foundation Land in The Sandbox",
        available: true,
      },
      {
        label: "Rare Mimi VX Exclusive Land",
        available: true,
      },
      {
        label: "Free Exclusive Merchandise",
        available: true,
      },
      {
        label: "Rare Mimi VX channel",
        available: true,
      },
      {
        label: "Receive 20% ETH from all the sales royalty fee",
        available: true,
      },
    ],
  },
]
