import { ReactNode, useEffect, useState } from "react"

import { Header, NewFooter, Loading } from "components"

import "./Layout.scss"
import imgHomeBgSrc from "assets/images/home-bg.webp"
import imgMouseInfoBgSrc from "assets/images/mouse-info-bg.webp"
import imgFaqBgSrc from "assets/images/faq-bg.webp"
import imgMimiBgSrc from "assets/gifs/mimi.gif"
import imgMintBgSrc from "assets/images/mint-background.webp"
import imgStoreBgSrc from "assets/images/store-background.webp"
import cn from "classnames"

type Props = {
  children?: ReactNode
  redirect?: boolean
  right?: boolean
  background: "home" | "faq" | "token" | "mouse-info" | "mimi" | "mint" | "store"
}

export const Layout = ({ redirect, children, background, right }: Props): JSX.Element => {
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  useEffect(() => {
    if (redirect) {
      location.reload()
    }
    document.getElementsByTagName("html")[0].style.overflow = "hidden"
    document.getElementsByTagName("html")[0].style.height = "100%"

    setTimeout(() => {
      setIsPageLoaded(true)
      document.getElementsByTagName("html")[0].style.overflow = "auto"
      document.getElementsByTagName("html")[0].style.height = "auto"
    }, 1500)
  }, [])

  return (
    <div
      className={cn("layout", {
        right: right,
      })}
    >
      {!isPageLoaded && <Loading />}

      {background === "home" && <img src={imgHomeBgSrc} alt="bg" className="layout-bg main" />}
      {background === "faq" && <img src={imgFaqBgSrc} alt="bg" className="layout-bg" />}
      {background === "mouse-info" && <img src={imgMouseInfoBgSrc} alt="bg" className="layout-bg" />}
      {background === "mimi" && <img src={imgMimiBgSrc} alt="bg" className="layout-bg mimi-bg" />}
      {background === "mint" && <img src={imgMintBgSrc} alt="bg" className="layout-bg mint-bg" />}
      {background === "store" && <img src={imgStoreBgSrc} alt="bg" className="layout-bg mint-bg" />}
      {background === "token" && (
        <iframe
          src="https://streamable.com/e/smwf8v?autoplay=1&nocontrols=1&muted=1"
          frameBorder="0"
          width="100%"
          height="100%"
          allowFullScreen
          allow="autoplay"
          className="layout-bg token-bg"
        />
      )}

      <div className="layout-content">
        <Header />
        {children}
        <NewFooter />
      </div>
    </div>
  )
}
