import React from "react"
import { useNavigate } from "react-router"
import { useMedia } from "use-media"

import { mixins, routes } from "utils"

import img1Src from "assets/images/mice/1.webp"
import img2Src from "assets/gifs/dancing.gif"
import img3Src from "assets/images/mice/3.webp"
import "./Mice.scss"

export const Mice = (): JSX.Element => {
  const isS = useMedia({ maxWidth: mixins.s })
  const navigate = useNavigate()
  const mice = [
    {
      img: img1Src,
      title: "Genesis Mimi",
      desc: "These are the first 1,501 Mimi to collect enough cheesy, some of them are legendary. They can bring you 5 $CHEESY every day, and they also become your best social avatar.",
    },
    {
      img: img2Src,
      title: "Mimi VX",
      desc: "Mimi VX were the first mice to step through the gate, and with the power of the gate, their avatars also evolved into Mimi VX, which will soon appear in The Sandbox's Jet Foundation.",
    },
    {
      img: img3Src,
      title: "?????",
      desc: "Residents of other realms are heading to the gates of Jet Foundation after finding our about Jet Foundation, and they will get there soon!",
    },
  ]

  // @ts-ignore
  return (
    <div className="mice">
      <div className="mice-title">Type of Mimi</div>
      <div className="mice-list">
        {mice.map((item, index) => (
          <div
            onClick={() => {
              if (index === 0) {
                navigate(routes.info)
                location.reload()
              }
              if (index === 1) {
                navigate(routes.mimi)
                location.reload()
              }
            }}
            key={index}
            className="mice-list-item"
          >
            <div className="mice-list-item-title">{item.title}</div>
            {index === 1 && !isS ? (
              <iframe
                src="https://streamable.com/e/omcnyt?autoplay=1&nocontrols=1&muted=1"
                frameBorder="0"
                width="100%"
                height="100%"
                allowFullScreen
                allow="autoplay"
                className="mice-list-item-img"
              />
            ) : (
              <img alt="mouse" className="mice-list-item-img" src={item.img} />
            )}
            <div className="mice-list-item-desc">{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
