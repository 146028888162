export const faqData: {
  title: string
  desc: string
}[] = [
  {
    title: "What is Jet Foundation?",
    desc: "Jet Foundation is an NFT on Ethereum blockchain. We feature randomly generated 2d and 3d mouse like nfts.",
  },
  {
    title: "Will there be two kinds of NFTs?",
    desc: "Yes. First we will release one of a kind 2D NFTS(Genesis Mimi) which will be upgradable to Mimi VX by using JETF token. Both will be ERC721 tokens.",
  },
  {
    title: "Will there be token in this project?",
    desc: "Yes we will have token called JETF token which will be used to upgrade NFTs and NFT holders will gain tokens as reward for holding NFTs as well.",
  },
  {
    title: "What other utilities will the token have?",
    desc: "Token holders will be able to vote in the DAO to take important decisions of the project, offline merchandise discount ,etc.",
  },
  {
    title: "Why named 'Jet Foundation'?",
    desc: "We all know mouse love cheese. So cheddar is a type of cheese. And we plan to expand to the metaverse. Hence, Jet Foundation.",
  },
  {
    title: "How is the project connected with the metaverse?",
    desc: "One of the main connection will be with The Sandbox. You will be able to use our Mimi VX there.",
  },
  {
    title: "Where and how to mint?",
    desc: "You will be able to mint Mimi in our website. You will need ETH to mint.",
  },
  {
    title: "How to get ETH?",
    desc: "You can get ETH from any exchange. Load your ETH to your Metamask or Trustwallet and connect to our website and complete the transaction to mint.",
  },
  {
    title: "How many ETH is needed for mint?",
    desc: "You will need 0.042 - 0.082 ETH to mint.",
  },
  {
    title: "What is an NFT?",
    desc: "An NFT, which stands for non-fungible token, is a unique unit of data employing technology that allows digital content—from videos to songs to images—to become logged and authenticated on cryptocurrency blockchains, primarily Ethereum. Once content is logged onto the blockchain, every transaction from transfers to sales is recorded on-chain, creating an easily accessible ledger of provenance and price history.",
  },
  {
    title: "Which wallet should I use?",
    desc: "There are many wallets but we think Metamask is the best for ETH.",
  },
  {
    title: "Where can I find rarities?",
    desc: "Rarity ranking will be available soon after the launch.",
  },
  {
    title: "Will you be on Opensea?",
    desc: "Yes we will be on Opensea.",
  },
  {
    title: "Will there be Whitelist?",
    desc: "Yes there will be Whitelist Presale.",
  },
  {
    title: "How do I buy?",
    desc:"We are not launched yet"
  },
]
