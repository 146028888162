import { useMedia } from "use-media"
import React, { Suspense, useRef, useState } from "react"

import { Layout } from "components"
import { mixins, useWindowHeight, useWindowWidth } from "utils"

import "./Store.scss"

export default function Model({ ...props }) {
  const [revert, setRevert] = useState(false)
  const group = useRef()
  const shirt = useRef()
  const onShirtClick = () => {
    if (!revert) {
      // @ts-ignore
      shirt.current.position.set(0, 0, 50)
      // @ts-ignore
      shirt.current.rotation.y = shirt.current.rotation.y + 600
    } else {
      // @ts-ignore
      shirt.current.position.set(0, 0, 0)
      // @ts-ignore
      shirt.current.rotation.y = shirt.current.rotation.y - 600
    }
    setRevert(!revert)
  }
}
export const Store = (): JSX.Element => {
  const isS = useMedia({ maxWidth: mixins.s })
  const height = useWindowHeight()
  const width = useWindowWidth()

  return (
    <Layout right background={"store"}>
      <div className="store" style={{ minHeight: isS ? "auto" : height - 160 }}>
        <div className="store-info">
          <h1 className="store-info-title">Physical items linked to digital assets across metaverses</h1>
          <p className="store-info-desc">
            Wearables 3.0 - NFT standard that links merch with blockchain-proven <br /> ownership to the digital items
            available across many metaverses
          </p>
          <div className="store-info-buttons">
            <button type="button" className="store-info-buttons-first" disabled={true}>
              BUY NOW
            </button>
            <button type="button" className="store-info-buttons-second" disabled={true}>
              HOW REDEEM WORKS?
            </button>
          </div>
        </div>
        <div
          style={{ width: isS ? `${width - 20}px` : "450px" }}
          // @ts-ignore
          className="store-3d"
        >

          <img
            className="store-3d-img"
            alt="play"
            src="https://www.uniqly.io/_next/image?url=%2Flp%2Fplay-with-card.svg&w=256&q=75"
            decoding="async"
            data-nimg="intrinsic"
          />
        </div>
      </div>
    </Layout>
  )
}
