import { useRef } from "react"

import { useClickOutside } from "utils"

import { ReactComponent as Close } from "assets/icons/close-modal.svg"
import "./MintModal.scss"

type Props = {
  onClose: () => void
}

export const MintModal = ({ onClose }: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useClickOutside(wrapperRef, () => {
    onClose()
  })

  const presaleData = [
    {
      title: "1st Presale",
      desc: (
        <p className="mint-modal-content-presale-block-desc">
          Draw 250 WL to mint 250 NFT <br /> (each WL can mint one nft only)
        </p>
      ),
    },
    {
      title: "2nd Presale",
      desc: (
        <p className="mint-modal-content-presale-block-desc">
          Draw 250 +🔺 WL to mint
          <br />
          250 +🔺 NFT
          <br />
          (each WL can mint nft only)
        </p>
      ),
    },
    {
      title: "Public sale + 🔼",
      desc: (
        <p className="mint-modal-content-presale-block-desc">
          WL who didn’t win the first and
          <br />
          second round lucky draw can
          <br />
          mint total + 🔼 Mimi 30 mins in
          <br />
          advance.
          <br />
          (each WL can mint 3 nft at most)
        </p>
      ),
    },
  ]

  return (
    <div className="mint-modal-wrapper">
      <div ref={wrapperRef} className="mint-modal-content animate__animated animate__zoomIn animate__faster">
        <Close className="mint-modal-content-icon" onClick={onClose} />
        If you did not qualify for the first round of whitelisting or were unsuccessful in the second round of
        purchases, you can mint up to three before the public sale (30 minutes).
        <br />
        <br />
        If you make a successful purchase in the first or second round, you will need to wait until the public sale
        begins before you can mint the remaining two.
        <br />
        <br />
        The mint time for members with whitelist and free mint qualification is 21:30 (UTC+8), the number of free mints
        will be separated from the number of whitelists.
        {/*<div className="mint-modal-content-presale">*/}
        {/*  <div className="mint-modal-content-presale-border" />*/}
        {/*  {presaleData.map((item, index) => (*/}
        {/*    <div key={index} className="mint-modal-content-presale-block">*/}
        {/*      <div className="mint-modal-content-presale-block-title">{item.title}</div>*/}
        {/*      {item.desc}*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
        {/*<div className="mint-modal-content-info">*/}
        {/*  <div className="mint-modal-content-info-item">🔺 — remaining NFT will distribute to 2nd presale</div>*/}
        {/*  <div className="mint-modal-content-info-item">*/}
        {/*    🔼 — remaining NFT will distribute to 30 mins before public sale*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
