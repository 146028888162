import { useMedia } from "use-media"

import { Claim, Layout } from "components"
import { mixins, useWindowHeight } from "utils"

import imgTokenBgSrc from "assets/gifs/token.gif"
import "./Token.scss"

export const Token = (): JSX.Element => {
  const isS = useMedia({ maxWidth: mixins.s })
  const height = useWindowHeight()

  return (
    <Layout right background={"token"}>
      <div className="token" style={{ minHeight: isS ? "auto" : height - 160 }}>
        {isS && <img src={imgTokenBgSrc} className="token-img" alt="bg" />}
        <div className="token-desc">
          <span className="bold">JETF Token</span> will be the official currency of Jet Foundation which was issued by
          none other than our Stuart Little. All the Genesis Mimi holders will get 5 JETF every day in rewards for
          holding Mimi. Users can spend 300 Cheesy to free mint Mimi VX NFTs and buy Jet Foundation exclusive merchandise.
          <br />
          <br />
          The Cheesy Token will have a lot of utilities - One of the most important ones is being able to use in our
          DAO, DAO provides all the token holders with an opportunity to take part in voting of important decisions
          regarding the project, this is very important to provide Decentralised experience.
        </div>
        <Claim />
      </div>
    </Layout>
  )
}
