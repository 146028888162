import { useNavigate } from "react-router"
import { useMedia } from "use-media"
import { useState } from "react"
import cn from "classnames"

import { links, mixins, routes, socials } from "utils"

import { ReactComponent as Burger } from "assets/icons/burger.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import imgLogoSrc from "assets/images/logo.png"
import "./Header.scss"

export const Header = (): JSX.Element => {
  const navigate = useNavigate()
  const [sidebar, setSidebar] = useState(false)
  const isM = useMedia({ maxWidth: mixins.m })

  const toggleSidebar = () => {
    setSidebar(!sidebar)
  }

  return (
    <div className="header">
      {isM && (
        <div
          className={cn("header-sidebar", {
            active: sidebar,
          })}
        >
          <div className="header-links">
            {links.map((link, index) =>
              link.link === "" ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://opensea.io/collection/cheddarverse-genesismimi"
                  className="header-links-item bold"
                  key={index}
                >
                  {link.title}
                </a>
              ) : (
                <div
                  onClick={() => {
                    navigate(link.link)
                    location.reload()
                  }}
                  className="header-links-item bold"
                  key={index}
                >
                  {link.title}
                </div>
              ),
            )}
          </div>
        </div>
      )}
      {!isM && (
        <div className="header-links">
          {links.map((link, index) =>
            link.link === "" ? (
              <a
                rel="noreferrer"
                target="_blank"
                href="https://opensea.io/collection/cheddarverse-genesismimi"
                className="header-links-item bold"
                key={index}
              >
                {link.title}
              </a>
            ) : (
              <div
                onClick={() => {
                  navigate(link.link)
                  location.reload()
                }}
                className="header-links-item bold"
                key={index}
              >
                {link.title}
              </div>
            ),
          )}
        </div>
      )}
      <div className="header-mobile">
        {isM && (
          <button className="header-mobile-burger" type="button" onClick={toggleSidebar}>
            {sidebar ? <Close className="header-mobile-close" /> : <Burger />}
          </button>
        )}
        <div
          onClick={() => {
            navigate(routes.home)
            location.reload()
          }}
          className="header-logo"
        >
          <img src={imgLogoSrc} alt="logo" className="header-logo-img" />
        </div>
      </div>
      <div className="header-social">
        {socials.map((social, index) => (
          <a className="header-social-item" rel="noreferrer" key={index} href={social.link} target="_blank">
            {social.icon}
          </a>
        ))}
      </div>
    </div>
  )
}
