import { useEffect, useState } from "react"
import { useMedia } from "use-media"

import { teamData } from "./teamData"

import imgBorderSrc from "assets/images/team/border.webp"
import { ReactComponent as Arrow } from "assets/icons/arrow.svg"
import "./Team.scss"

export const Team = (): JSX.Element => {
  const [translateX, setTranslateX] = useState(0)
  const [goNext, setGoNext] = useState(true)
  const isS = useMedia({ maxWidth: "600px" })

  const onNextClick = () => {
    if (isS) {
      return setTranslateX(translateX - 320)
    }

    setTranslateX(translateX - 420)

    console.log(translateX)
  }

  const onPrevClick = () => {
    if (isS) {
      return setTranslateX(translateX + 320)
    }

    setTranslateX(translateX + 420)
  }

  const nextInterval = () => {
    if (translateX === -3360 || translateX === -3520) {
      return setGoNext(false)
    }
    if (isS) {
      return setTranslateX(translateX - 320)
    }
    return setTranslateX(translateX - 420)
  }
  const prevInterval = () => {
    if (translateX === 0) {
      return setGoNext(true)
    }
    if (isS) {
      return setTranslateX(translateX + 320)
    }
    return setTranslateX(translateX + 420)
  }

  useEffect(() => {
    if (!goNext) {
      const id = setInterval(prevInterval, 2000)
      return () => clearInterval(id)
    }
    const id = setInterval(nextInterval, 2000)
    return () => clearInterval(id)
  }, [goNext, translateX, isS])

  return (
    <div className="team">
      <div className="team-info">
        <div className="team-title">Our Team</div>
        {!isS && (
          <div className="team-info-arrows">
            <button type="button" onClick={onPrevClick} disabled={translateX === 0} className="team-info-arrows-arrow">
              <Arrow />
            </button>
            <button
              type="button"
              onClick={onNextClick}
              disabled={translateX === -3360}
              className="team-info-arrows-arrow"
            >
              <Arrow />
            </button>
          </div>
        )}
      </div>
      <div className="team-list-wrapper">
        <div style={{ transform: `translateX(${translateX}px)` }} className="team-list">
          {teamData.map((item, index) => (
            <div key={index} className="team-list-item">
              <img src={imgBorderSrc} alt="team-border" className="team-list-item-border" />
              <img alt="member" className="team-list-item-img" src={item.img} />
              <div className="team-list-item-info">
                <div className="team-list-item-title">{item.title}</div>
                <div className="team-list-item-position">{item.position}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isS && (
        <div className="team-info-arrows">
          <button type="button" onClick={onPrevClick} disabled={translateX === 0} className="team-info-arrows-arrow">
            <Arrow />
          </button>
          <button
            type="button"
            onClick={onNextClick}
            disabled={translateX === -3520}
            className="team-info-arrows-arrow"
          >
            <Arrow />
          </button>
        </div>
      )}
    </div>
  )
}
